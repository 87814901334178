// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-brand-identity-js": () => import("./../../../src/pages/brand-identity.js" /* webpackChunkName: "component---src-pages-brand-identity-js" */),
  "component---src-pages-frontend-development-js": () => import("./../../../src/pages/frontend-development.js" /* webpackChunkName: "component---src-pages-frontend-development-js" */),
  "component---src-pages-hire-js": () => import("./../../../src/pages/hire.js" /* webpackChunkName: "component---src-pages-hire-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-online-marketing-js": () => import("./../../../src/pages/online-marketing.js" /* webpackChunkName: "component---src-pages-online-marketing-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-ux-design-js": () => import("./../../../src/pages/ux-design.js" /* webpackChunkName: "component---src-pages-ux-design-js" */)
}

